@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  background-color: theme('colors.gray.100');
  padding: 0;
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  /*color: inherit;*/
  color: #A070f3;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
p,
ul {
  margin: 0;
}

ul {
  padding: 0;
  list-style: none;
}

button {
  padding: 0.5rem 1rem;
  /* font-weight: bold; */
}
